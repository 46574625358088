import React from "react"
// import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Images from '../components/images'
// import useSound from 'use-sound';
// import boopSfx from '../sounds/pop-down.mp3';

const IndexPage = () => {
  // const [play, { stop }] = useSound(boopSfx, { volume: 0.1 }); 
  return (
<Layout>
    <SEO title="About me" />
    <section>
      <div className="container">
        <div className="row">
          <div className='col-md-6 main-image'  
            data-sal="slide-up"
            data-sal-delay="100"
            data-sal-easing="ease"
            data-sal-duration="1000">
            <Images alt="Linas Vildžiūnas" filename="linas.jpg" />
          </div>
          <div className='col-md-6' 
          data-sal="slide-up"
          data-sal-delay="300"
          data-sal-easing="ease"
          data-sal-duration="1000"
          >
            <h3 className="small-title">About me</h3>
            <p>Hi, my name is Linas. For over 5 years I've gained hands-on experience working with <b>eCommerce</b>, <b>media</b> and <b>tech</b> related businesses around the world providing web development and design services by helping them to reach their goals, acquire users and generate millions in revenue.</p>
            <p>I consider myself a multi-disciplinary web developer and designer. I worked on projects for small startups, large enterprise businesses, influencers, celebrities and best athletes in the world.</p>
            <p>Contact me <a href="mailto:linas@vildziunas.com">linas@vildziunas.com</a></p>
          </div>
        </div>
        <hr ></hr>

        <div className="row"
        data-sal="slide-up"
        data-sal-delay="400"
        data-sal-easing="ease"
        data-sal-duration="1000"
        >
          <div className="col">
          <h3 className="small-title">Skillset</h3>
          <ul className="skills-box">
          {/* <li onMouseEnter={play} onMouseLeave={stop} className="skills-list"><p className="skills-text">Web Development</p></li> */}
            <li className="skills-list"><p className="skills-text">Web Development</p></li>
            <li className="skills-list"><p className="skills-text">UI/UX Design</p></li>
            <li className="skills-list"><p className="skills-text">Conversion Rate Optimization / CRO</p></li>
            <li className="skills-list"><p className="skills-text">SEO</p></li>
            <li className="skills-list"><p className="skills-text">Social Media Advertising</p></li>
            </ul>
          </div>

        </div>

        <div className="row"
        data-sal="slide-up"
        data-sal-delay="400"
        data-sal-easing="ease"
        data-sal-duration="1000"
        >
        <div className="col">
        <h3 className="small-title">Toolkit</h3>
          <ul className="skills-box">
                  <li className="skills-list">
                      <p className="skills-text">
                        <i className="fab fa-html5" /> HTML / CSS
                      </p>
                    </li>

                    <li className="skills-list">
                      <p className="skills-text">
                        <i className="fab fa-js" /> Javascript
                      </p>
                    </li>

                    <li className="skills-list">
                      <p className="skills-text">
                        <i className="fab fa-react" /> React
                      </p>
                    </li>

                    <li className="skills-list">
                      <p className="skills-text">
                        <i className="fab fa-wordpress" /> Wordpress
                      </p>
                    </li>
                    
                    
                    <li className="skills-list">
                      <p className="skills-text"><i class="fab fa-shopify"></i> Shopify</p>
                    </li>
                    
                    <li className="skills-list">
                      <p className="skills-text">
                        <i className="fab fa-squarespace" /> Squarespace
                      </p>
                    </li>
                    
                    <li className="skills-list">
                      <p className="skills-text">
                        <i className="fab fa-sketch" /> Sketch
                      </p>
                    </li>
                    <li className="skills-list">
                      <p className="skills-text">
                        <i className="fab fa-adobe" /> Photoshop
                      </p>
                    </li>
                    <li className="skills-list">
                      <p className="skills-text"><i className="fab fa-google"></i> Google Analytics</p>
                    </li>
                  </ul>
        </div>
        </div>
<hr/>

<div className="row"
data-sal="slide-up"
data-sal-delay="400"
data-sal-easing="ease"
data-sal-duration="1000"
>
  <div className="col">
    <h3 className="small-title">Personal</h3>
    <p>Basketball fan and motorcycle enthusiast who loves to travel on motorcycles and <a href="https://www.instagram.com/p/BmD_BCuh-pn/">customize them</a>. Fun fact, I am a music production graduate and can help you produce and record your album.</p>
  </div>
</div>

      </div>
    </section>

  </Layout>
  );
};

export default IndexPage;
